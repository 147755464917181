<template>
  <div :class="$style.news">
    <BjSearch @refresh="refresh" @init="init">
      <BjInput
        v-model="search.title"
        allow-clear
        label="搜索资讯"
        v-bind="layout"
        placeholder="请输入资讯标题"
        @pressEnter="getList"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="id"
        label="所属分组"
        inner-search
        placeholder="请选择分组"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="发布状态"
        inner-search
        placeholder="请选择状态"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
    </BjSearch>
    <div :class="$style.container">
      <a-row :class="$style.header">
        <a-col span="4" :class="$style.title"> 资讯列表 </a-col>
        <a-col span="20" :class="$style.btns">
          <div :class="[$style.badge, recycleTotal === 0 ? $style.badgeno : null]">
            <dustbin v-permission="'app.news.manage-recycle.view'" :count="recycleTotal" @refresh="getList" />
          </div>
        </a-col>
      </a-row>
      <a-table
        :class="$style.table"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        row-key="id"
      >
        <template #cover="item">
          <div :class="$style.cover">
            <img :src="item.cover.cover" />
          </div>
        </template>
        <template #status="item">
          <BjTag :type="item.status === 1 ? 'primary' : 'default'">{{ item.status === 1 ? '已发布' : '未发布' }}</BjTag>
        </template>
        <template #action="item">
          <bj-link v-permission="'app.news.manage-edit'" type="primary" @click="onEdit(item)"> 编辑 </bj-link>
          <a-popconfirm placement="topLeft" title="确定要删除资讯吗？" @confirm="onDelete(item.id)">
            <bj-link v-permission="'app.news.manage-delete'" type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { newsService } from '@/service'

import dustbin from './dustbin.vue'

const service = new newsService()

export default {
  name: 'Home',
  components: {
    dustbin,
  },
  data() {
    return {
      search: {
        title: null,
        status: 0,
        group_id: '',
      },
      data: [],
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      page: 1,
      pageSize: 20,
      total: 0,
      recycleTotal: 0,
      typeData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '已发布',
          id: 1,
        },
        {
          name: '未发布',
          id: 2,
        },
      ],
      groupData: [
        {
          name: '全部分组',
          id: '',
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '资讯封面',
          width: 200,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '资讯标题', dataIndex: 'title' },
        { title: '所属分组', dataIndex: 'group_name' },
        {
          title: '是否置顶',
          dataIndex: 'is_top',
          customRender: value => (value === 1 ? '是' : '否'),
        },
        { title: '发布时间', dataIndex: 'publish_time' },
        {
          title: '发布状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          width: 180,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getRecycleTotal()
    this.getGroup()
    this.getList()
  },
  methods: {
    async getGroup() {
      const { data } = await service.groupList()
      this.groupData = this.groupData.concat(data)
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          ...this.search,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    onEdit(item) {
      this.$router.push({
        name: 'newsAdd',
        query: {
          id: item.id,
        },
      })
    },
    async onDelete(_id) {
      try {
        await service.delete({
          id: _id,
        })
        this.$message.success('删除成功')
        this.page = 1
        this.getList()
      } catch (e) {}
    },
    async getRecycleTotal() {
      const { data } = await service.recycleTotal()
      this.recycleTotal = data
    },
    refresh() {
      this.page = 1
      this.getList()
      this.getRecycleTotal()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        title: null,
        status: 0,
        group_id: '',
      }
      this.refresh()
    },
  },
}
</script>

<style lang="less" module>
.news {
  .container {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    min-height: calc(100vh - 252px);

    .title {
      color: #000;
      font-size: 16px;
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .mode {
        width: 93px;
        height: 20px;
        margin: 0 20px;
        text-align: right;
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;

        i {
          position: relative;
          top: -5px;
        }
      }

      .line {
        height: 20px;
        margin: 0 20px 0 10px;
        border-right: 1px solid #eee;
      }

      .badge {
        width: 100px;
        height: 30px;
        color: #000;
        line-height: 30px;
        cursor: pointer;

        i {
          position: relative;
          top: 2px;
          left: -2px;
          color: #000;
          font-size: 20px;
        }
      }

      .badgeno {
        width: 70px;
      }
    }
  }

  .header {
    padding: 0 0 20px 0;
  }

  .table {
    .cover {
      img {
        width: 70px;
        height: 44px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
